import $ from 'jquery'

$(document).on('click', '.next-page', function (e) {
  e.preventDefault()

  var container = $(this).parent()

  $(this).addClass('loading').text('Loading...')

  $.get($(this).attr('href'), function (data) {
    container.replaceWith(data)
  })
})
