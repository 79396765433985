import $ from 'jquery'

$(document).on('click', '[data-toggle="remote-modal"]', function (e) {
  e.preventDefault()

  const url = $(this).attr('href')

  $.get(url, function (data) {
    const modal = $('body').append(data).find('.modal')

    $(modal).on('hidden.bs.modal', function () {
      modal.remove()
    })

    $(modal).modal('show')
  })
})
