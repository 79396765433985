/* global FB */

import $ from 'jquery'

$(document).on('click', '[data-behavior="facebook-share-button"]', function (e) {
  e.preventDefault()

  FB.ui({
    method: 'share',
    href: $(this).data('url')
  }, function (response) {})
})
