import $ from 'jquery'

$(document).on('show.bs.collapse', '.accordion-content', function (e) {
  const $item = $(this).closest('.accordion-item')
  const $open = $($(this).data('parent')).find('.collapse.show')

  let additionalOffset = 0
  if ($item.prevAll().filter($open.closest('.accordion-item')).length !== 0) {
    additionalOffset = $open.height()
  }

  $('html, body').animate({
    scrollTop: $item.offset().top - additionalOffset
  }, 500)
})
