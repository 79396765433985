import $ from 'jquery'

const SECOND = 1000
const MINUTE = 1000 * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const WEEK = DAY * 7

export default class Countdown {
  constructor (el) {
    this.$el = $(el)

    this.endDate = new Date(this.$el.attr('datetime')).getTime()

    this.setup()
  }

  start () {
    this.tick()
  }

  stop () {
    clearInterval(this.timer)
  }

  setup () {
    this.weeks = $('<span class="unit"><span class="target"></span><small>weeks</small></span>').appendTo(this.$el).find('.target')
    this.days = $('<span class="unit"><span class="target"></span><small>days</small></span>').appendTo(this.$el).find('.target')
    this.hours = $('<span class="unit"><span class="target"></span><small>hours</small></span>').appendTo(this.$el).find('.target')
    this.mins = $('<span class="unit"><span class="target"></span><small>mins</small></span>').appendTo(this.$el).find('.target')
    this.secs = $('<span class="unit"><span class="target"></span><small>secs</small></span>').appendTo(this.$el).find('.target')

    this.startTimer()
  }

  tick () {
    const now = new Date().getTime()
    const t = this.endDate - now

    if (t > 0) {
      const weeks = Math.floor(t / WEEK)
      const days = Math.floor((t % WEEK) / DAY)
      const hours = Math.floor((t % DAY) / HOUR)
      const mins = Math.floor((t % HOUR) / MINUTE)
      const secs = Math.floor((t % MINUTE) / SECOND)

      this.weeks.text(this.format(weeks))
      this.days.text(this.format(days))
      this.hours.text(this.format(hours))
      this.mins.text(this.format(mins))
      this.secs.text(this.format(secs))
    } else {
      this.$el.html('Tenders <span class="d-none d-sm-inline">&nbsp;are now&nbsp;</span> closed')
      this.stop()
    }
  }

  startTimer () {
    this.timer = setInterval(() => this.tick(), 1000)
  }

  format (value) {
    return value.toString().padStart(2, '0')
  }
}

$(function () {
  $('[data-behavior="countdown-timer"]').each(function () {
    const countdown = new Countdown(this)
    countdown.start()
  })
})
