import $ from 'jquery'

$(function () {
  const body = $('body')
  const toggle = $('.navbar-toggler')

  toggle.on('click', function (e) {
    e.stopImmediatePropagation()
    body.toggleClass('nav-active')
  })

  body.on('click touchstart', function (e) {
    const navExpanded = body.hasClass('nav-active')
    const clickedNavigation = $(e.target).closest('.primary-nav').length

    if (navExpanded && !clickedNavigation) {
      e.stopPropagation()
      e.preventDefault()

      body.removeClass('nav-active')
    }
  })
})
