import $ from 'jquery'

import Flickity from 'flickity'
import 'flickity-fullscreen'
import 'flickity-imagesloaded'

import { debounce } from 'debounce'

$(function () {
  if ($('.listing-slideshow').length) {
    const flkty = new Flickity('.listing-slideshow', {
      wrapAround: true,
      adaptiveHeight: false,
      imagesLoaded: true,
      fullscreen: true
    })

    const resize = () => {
      $('.flickity-viewport')[0].style.height = null
      flkty.resize()
    }

    $(window).on('resize', debounce(resize, 50))
  }
})
