import $ from 'jquery'

import 'rangeslider.js'

const GST = 0.15

const LOCAL_EYES_FLAT_FEE = 7500

const FIRST_TIER_LIMIT = 400000
const RATE_THEREAFTER = 0.025

function numberWithCommas (value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function formatCurrency (value) {
  return '$' + numberWithCommas(value)
}

function formatPercentage (percentage) {
  return percentage.toFixed(1) + '%'
}

function addGST (value) {
  return value * (1 + GST)
}

function recalculate () {
  const propertyValue = parseInt($('#property-value-slider').val(), 10)
  const agentCommission = parseFloat($('#agent-commission-slider').val())

  $('#property-value').text(formatCurrency(propertyValue))
  $('#agent-commission').text(formatPercentage(agentCommission))

  const firstTier = Math.min(propertyValue, FIRST_TIER_LIMIT)
  const firstTierCost = firstTier * (agentCommission / 100)

  const remainder = Math.max(propertyValue - FIRST_TIER_LIMIT, 0)
  const remainderCost = remainder * RATE_THEREAFTER

  const agentCost = addGST(firstTierCost + remainderCost)
  const localEyesCost = addGST(LOCAL_EYES_FLAT_FEE)

  const savings = Math.max(Math.round(agentCost - localEyesCost), 0)

  $('#savings-result').text(formatCurrency(savings) + '*')
}

$(function () {
  $('input[type="range"]').rangeslider({
    polyfill: false,
    onInit: function () {
      recalculate()
      this.$element.closest('.slider').find('.value').css('left', this.position + 'px')
    },
    onSlide: function (position, value) {
      recalculate()

      this.$element.closest('.slider').find('.value').css('left', position + 'px')
    }
  })
})
