import 'jquery'
import 'bootstrap/dist/js/bootstrap'

import Rails from '@rails/ujs'

import 'components/accordion'
import 'components/navigation'
import 'components/slideshow'
import 'components/pagination'
import 'components/countdown'
import 'components/facebook'
import 'components/select'
import 'components/lightbox'
import 'components/remote-modal'
import 'components/savings-calculator'

Rails.start()
