import $ from 'jquery'

import 'lightcase'

function initLightcase () {
  $('a[data-rel^="lightcase"]').lightcase({
    maxWidth: 'none',
    maxHeight: 'none',
    showCaption: false,
    video: {
      width: 1280,
      height: 720,
      poster: '',
      preload: 'auto',
      controls: true,
      autobuffer: true,
      autoplay: true,
      loop: false
    },
    iframe: {
      width: 1670,
      height: 1080
    }
  })
}

$(initLightcase)

window.initLightcase = initLightcase
