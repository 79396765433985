import $ from 'jquery'

function update (element) {
  const target = $(element.data('target'))

  if (element.val() === 'Other') {
    target.show().attr('disabled', false)
  } else {
    target.hide().attr('disabled', true)
  }
}

$(document).on('change', '[data-toggle="other"]', function () {
  update($(this))
})

$(function () {
  $('[data-toggle="other"]').each(function () {
    update($(this))
  })
})
